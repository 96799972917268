import React from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import "./regs.css"
import { Link } from 'react-router-dom'

export default function RegSuccess() {
  return (
    <>
      <Header/>
        <main>
          <div className="main">
            <div className='reg_success_block'>
              <h1 className='h1_reg_success'>Реєстрація пройшла успішно</h1>
            </div>
            <p className='reg_success_block_link'>
              <Link to="/">На головну </Link>| <Link to="/login"> Увійти</Link>
            </p>
          </div>
        </main>
      <Footer/>
    </>
  )
}
