import React, {useState, useEffect} from 'react'
import AdminPanelAside from '../../../components/AdminPanelAside/AdminPanelAside'
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';
import { Navigate } from 'react-router-dom';
import "./editCat.css";
import axios from 'axios';
import LoadingPage from '../../Loading/LoadingPage';

export default function EditCategory() {

  const [categories, setCategories] = useState([]);

  const [userRole, setUserRole] = useState(null); // Initialize state for user role

  const [isLoading, setIsLoading] = useState(true);

  const [addCategoryName, setAddCategoryName] = useState('');

  const [selectedCategory, setSelectedCategory] = useState('');

  async function DeleteCategory(e){
    e.preventDefault();

    if (selectedCategory) {
        try {
          console.log('Sending request to delete category:', selectedCategory);
          const response = await axios.post('/api/DeleteCategory', {
            category: selectedCategory // Передаем выбранную категорию в теле запроса
          });

          console.log('Response from server:', response);

          if (response.status === 200) {
              alert('Категорію успішно видалено');
              // Возможно, обновите список категорий здесь
          } else {
              alert('Сталася помилка при видаленні категорії');
          }
        } catch (error) {
            console.error('Error:', error);
            alert('Сталася помилка при видаленні категорії');
        }
    } else {
        alert('Будь ласка, виберіть категорію');
    }
  };

  async function AddNewCategory(e) {
      e.preventDefault(); // предотвращает перезагрузку страницы
  
      try {
        const response = await axios.post('/api/AddNewCategory', {
          addCategoryName: addCategoryName,
        });
  
        const data = response.data;
        if (data.message === "Category added successfully") {
          alert("Категорію додано успішно");
        } else {
          alert("Сталася помилка під час створення нової категорії");
        }
      } catch (error) {
        console.error('Error:', error);
        alert("Помилка мережі!");
      }
  }

  useEffect(() => {
      fetch('/api/categories') // Отправка GET запроса к эндпоинту /categories на вашем сервере
        .then(response => response.json())
        .then(data => {
          setCategories(data);
        })
        .catch(error => console.error('Ошибка при получении данных о категориях:', error));
    }, []);

  useEffect(() => {
      // Выполнение запроса к серверу для получения информации о пользователе
      fetch('/api/getUserInfo')
          .then(response => {
              if (!response.ok) {
                  throw new Error('Failed to fetch user info');
              }
              return response.json();
          })
          .then(data => {
              setUserRole(data.role);
          })
          .catch(error => console.error('Ошибка при получении информации о пользователе:', error))
          .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
      return <LoadingPage/>;
  }
  return (
    <>
        {userRole === "admin" ? (
                <>
                    <Header/>
                    <main>
                        <div className="container">
                            <div className="admin_panel">
                                <AdminPanelAside/>
                                <div className="admin_panel_window">
                                    <form
                                     action="POST"
                                     className='admin_panel_edit_cat_form'
                                     onSubmit={DeleteCategory}
                                    >
                                        <label htmlFor="">Видалити категорію:</label>
                                        <select
                                         name='category' 
                                         id='category' 
                                         className='category_select' 
                                         defaultValue="all category"
                                         onChange={(e) => setSelectedCategory(e.target.value)}
                                        >
                                            <option value="all category" disabled>Видаліть категорію</option>
                                            {categories.length > 0 ? (
                                                categories.map(category => (
                                                    <option key={category.id} value={category.id}>{category.name}</option>
                                                ))
                                            ) : (
                                                <option disabled>На данний момент категорії відсутні</option>
                                            )}
                                        </select>
                                        <button type='submit' className='admin_panel_edit_cat_button'>Видалити</button>
                                    </form>
                                    <form
                                     action="POST"
                                     className='admin_panel_edit_cat_form'
                                     onSubmit={AddNewCategory}
                                    >
                                        <label>Додати категорію:</label>
                                        <input
                                         type="text"
                                         placeholder='Назва категорії'
                                         name='add_category'
                                         value={addCategoryName}
                                         required
                                         onChange={(e) => setAddCategoryName(e.target.value)} />
                                        <button type='submit' className='admin_panel_edit_cat_button'>Додати</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </main>
                    <Footer/>
                </>
            ) : (
                <Navigate replace to="/" />
            )}
    </>
  )
}