import * as React from "react";
import * as ReactDOM from "react-dom/client";
import App from './App'
import Error from './pages/Error/Error'
import './css/style.css'
import {RouterProvider, createBrowserRouter} from 'react-router-dom';
import Registration from "./pages/Registration/Registration";
import AdminPanel from "./pages/AdminPanel/AdminPanel";
import Login from "./pages/Login/Login";
import RegSuccess from "./pages/RegSuccess/RegSuccess";
import Products from "./pages/Products/Products";
import ProductPage from "./pages/ProductPage/PoductPage"
import Account from "./pages/Account/Account";
import ChangePassword from "./pages/AdminPanel/ChangePassword/ChangePassword";
import Statistic from "./pages/AdminPanel/Statistic/Statistic";
import AddNewProduct from "./pages/AdminPanel/AddNewProduct/AddNewProduct";
import EditCategory from "./pages/AdminPanel/EditCategory/EditCategory";
import LoadingPage from "./pages/Loading/LoadingPage";


const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        errorElement: <Error/>
    },
    {
        path: "/registration",
        element: <Registration/>
    },
    {
      path: "/admin-panel",
      element: <AdminPanel/>
    },
    {
      path: "/login",
      element: <Login/>
    },
    {
      path: "/registration_success",
      element: <RegSuccess/>
    },
    {
      path: "/products/:id",
      element: <Products/>
    },
    {
      path: "/product/:id",
      element: <ProductPage/>
    },
    {
      path: "/account",
      element: <Account/>
    },
    {
      path: "/admin-panel-change-pass",
      element: <ChangePassword/>
    },
    {
      path: "/admin-panel-statistic",
      element: <Statistic/>
    },
    {
      path: "/admin-panel-add-new-product",
      element: <AddNewProduct/>
    },
    {
      path: "/admin-panel-edit-category",
      element: <EditCategory/>
    },
]);


ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
);