import React, { useState, useEffect } from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import { Navigate } from 'react-router-dom'
import "./account.css"
import profile_picture from "../../img/profile-picture.jpg"
import axios from "axios"
import LoadingPage from '../Loading/LoadingPage'

export default function Account() {

    const [userEmail, setUserEmail] = useState(null);

    const [userRole, setUserRole] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetch('/api/getUserInfo')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch user info');
                }
                return response.json();
            })
            .then(data => {
                setUserEmail(data.email);
                setUserRole(data.role);
            })
            .catch(error => console.error('Ошибка при получении информации о пользователе:', error))
            .finally(() => setIsLoading(false));
    }, []);

    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [phone, setPhone] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [sex, setSex] = useState('');


    const deleteCookie = async () => {
        try {
            const response = await axios.post('/api/delete-cookie', {}, {
                withCredentials: true // Включаем куки в запрос
            });

            if (response.status === 200) {
                window.location.reload();
            } else {
                alert('Failed to delete cookie');
            }
        } catch (error) {
            console.error('Error deleting cookie:', error);
            alert('Error deleting cookie');
        }
    };

    if (isLoading) {
        return <LoadingPage/>;
    }

  return (
    <>
        {userEmail ? (
            userRole === "user" ? (
            <>
                <Header/>
                    <div class="container">
                        <aside class="sidebar">
                            <section class="profile">
                                <img src={profile_picture} alt="Profile Picture" class="profile-picture"></img>
                                <h2>{userEmail}</h2>
                            </section>
                            <section class="menu">
                                <ul>
                                    <li><a href="#">Замовлення</a></li>
                                    <li class="active"><a href="#">Інформація</a></li>
                                    <li><a href="#">Адреса</a></li>
                                    <li><a href="#">Потребую допомоги</a></li>
                                    <li>
                                        <button onClick={deleteCookie}>Вийти з профілю</button>
                                    </li>
                                </ul>
                            </section>
                        </aside>
                        <main class="main-content">
                            <h1>Персональна інформація</h1>
                            <form action="#">
                                <div class="form-group">
                                    <label for="first-name">Ім'я</label>
                                    <input type="text" id="first-name" name="first-name"></input>
                                </div>
                                <div class="form-group">
                                    <label for="last-name">Прізвище</label>
                                    <input type="text" id="last-name" name="last-name"></input>
                                </div>
                                <div class="form-group">
                                    <label for="phone">Телефон</label>
                                    <div class="phone-input">
                                        <input type="tel" id="phone" name="phone" value={"+380"}></input>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="birthdate">Дата народження</label>
                                    <input type="date" id="birthdate" name="birthdate"></input>
                                </div>
                                <div class="form-group">
                                    <label>Стать</label>
                                    <div class="gender-options">
                                        <input type="radio" id="male" name="gender" value="male"></input>
                                        <label for="male">Чоловік</label>
                                        <input type="radio" id="female" name="gender" value="female"></input>
                                        <label for="female">Жінка</label>
                                    </div>
                                </div>
                                <div class="form-buttons">
                                    <button type="reset" class="cancel-button">Відміна</button>
                                    <button type="submit" class="save-button">Зберегти</button>
                                </div>
                            </form>
                        </main>
                    </div>
                <Footer/>
            </>
            ) : (
                <Navigate replace to="/admin-panel" />
            )
        ):(
            <Navigate replace to="/login" />
        )}
    </>
  )
}
