 import React, { useState, useEffect } from 'react'
 import './modal.css';
import { Link } from 'react-router-dom';
 
 export default function Modal() {

    const [modal, setModal] = useState(false);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        fetch('/api/categories')
          .then(response => response.json())
          .then(data => {
            setCategories(data);
          })
          .catch(error => console.error('Ошибка при получении данных о категориях:', error));
    }, []);

    const toggleModal = () => {
        setModal(!modal)
    }

   return (
     <>
        <button
         className='btn-modal'
         onClick={toggleModal}
        >
            Каталог
        </button>

        {modal && (
            <div className="modal">
                <div
                className='overlay'
                onClick={toggleModal}
                ></div>
                <div className="modal-content">
                    <ul className='category_ul'>
                        {categories.length > 0 ? (
                            categories.map(category => (
                                <Link className='category_link' to={`/products/${category.id}`}>
                                    <li className='category_li' key={category.id}>{category.name}</li>
                                </Link>
                            ))
                        ) : (
                            <li>На данный момент категории отсутствуют</li>
                        )}
                    </ul>
                    <span
                     className='close-modal' 
                     onClick={toggleModal}
                    >&times;</span>
                </div>
            </div>
        )}
     </>
   )
 }
 