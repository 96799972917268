import React, {useState, useEffect} from "react";
import {Link, Navigate} from 'react-router-dom';
import wallpaper_market from '../../img/wallpaper market.png';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./admin_panel.css"
import AdminPanelAside from "../../components/AdminPanelAside/AdminPanelAside";
import LoadingPage from '../Loading/LoadingPage'

export default function AdminPanel(){

    const [userRole, setUserRole] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetch('/api/getUserInfo')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch user info');
                }
                return response.json();
            })
            .then(data => {
                setUserRole(data.role);
            })
            .catch(error => console.error('Ошибка при получении информации о пользователе:', error))
            .finally(() => setIsLoading(false));
    }, []);

    if (isLoading) {
        return <LoadingPage/>;
    }

    return (
        <>
            {userRole === "admin" ? (
                <>
                    <Header/>
                    <main>
                        <div className="container">
                            <div className="admin_panel">
                                <AdminPanelAside/>
                                <section className="admin_panel_window">
                                    <Link to="/">
                                        <img alt="Логотип шпалерного ринку" className='wallpaper_market_logo' src={wallpaper_market}/>
                                    </Link>
                                </section>
                            </div>
                        </div>
                    </main>
                    <Footer/>
                </>
            ) : (
                <Navigate replace to="/" />
            )}
        </>
    );
}