import React from 'react'
import Button from '../../Button/Button';
import wallpaper_market from '../../../img/wallpaper market.png';
import { Link } from "react-router-dom";
import market1 from '../../../img/market1.png';
import market2 from '../../../img/market2.png';
import market3 from '../../../img/market3.png';
import './slider.css'

export default function Slider() {
  return (
    <>
        <input defaultChecked type='radio' name='respond' id='desktop' />
        <article id='slider'>
            <input defaultChecked type='radio' name='slider' id='switch1' />
            <input type='radio' name='slider' id='switch2' />
            <input type='radio' name='slider' id='switch3' />
            <div id='slides'>
              <div id='overflow'>
                <div className='image'>
                  <article>
                    <div className='a1'>
                      <Link to='/'>
                        <img className='wp_logo' src={wallpaper_market} alt='Шпалерний ринок' />
                      </Link>
                      <Button value={"В магазин"}/>
                    </div>
                    <img src={market1} alt='Шпалерний ринок' />
                  </article>
                  <article>
                    <div className='a2'>
                      <Link to='/'>
                        <img className='wp_logo' src={wallpaper_market} alt='Шпалерний ринок' />
                      </Link>
                      <Button value={"В магазин"}/>
                    </div>
                    <img src={market2} alt='Шпалерний ринок' />
                  </article>
                  <article>
                    <div className='a1'>
                      <Link to='/'>
                        <img className='wp_logo's src={wallpaper_market} alt='Шпалерний ринок' />
                      </Link>
                      <Button value={"В магазин"}/>
                    </div>
                    <img src={market3} alt='Шпалерний ринок' />
                  </article>
                </div>
              </div>
            </div>
            <div id='controls'>
              <label htmlFor='switch1'></label>
              <label htmlFor='switch2'></label>
              <label htmlFor='switch3'></label>
            </div>
            <div id='active'>
              <label htmlFor='switch1'></label>
              <label htmlFor='switch2'></label>
              <label htmlFor='switch3'></label>
            </div>
        </article>
    </>
  )
}
