import React from 'react'
import { Link } from "react-router-dom";
import wallpaper_market from '../../img/wallpaper market.png';
import facebook from '../../img/Facebook.png';
// import twitter from '../../img/Twitter.png';
// import instagram from '../../img/Instagram.png';
// import linked_in from '../../img/LinkedIn.png';
// import youtube from '../../img/YouTube.png';
import './footer.css';

export default function Footer() {
    return (
      <footer className='footer'>
        <div className='container2'>
          <div className='footer_container'>
            <div className='footer_container_1'>
              <Link to='/'>
                <img src={wallpaper_market} alt='Шпалерний ринок' className='footer_container_1_img' />
              </Link>
              <article className='footer_container_1_2'>
                <a href='https://www.facebook.com/groups/665387070235556' rel='noreferrer' target='_blank' className='social_media'>
                  <img src={facebook} alt='Facebook' />
                </a>
                {/* <a href='#.com' target='_blank' className='social_media'>
                  <img src={twitter} alt='Twitter' />
                </a>
                <a href='#.com' target='_blank' className='social_media'>
                  <img src={instagram} alt='Instagram' />
                </a>
                <a href='#.com' target='_blank' className='social_media'>
                  <img src={linked_in} alt='LinkedIn' />
                </a>
                <a href='#.com' target='_blank' className='social_media'>
                  <img src={youtube} alt='YouTube' />
                </a> */}
              </article>
              <article className='footer_container_1_3'>
                <a href="https://maps.app.goo.gl/PC2JmXRLhJXxiFXe9" rel="noreferrer" target='_blank'>
                  <u>
                    Вулиця Зодчих, 74, Київ, Україна, 02000
                  </u>
                </a>
              </article>
            </div>
            <article className='footer_container_2'>
              <div className='products'>
                {/* <p className='footer_container_2_text'>Продукти</p>
                <a href='category.html' className='footer_container_2_links'>
                  Категорії
                </a>
                <a href='#.com' className='footer_container_2_links'>
                  Ціни
                </a>
                <a href='#.com' className='footer_container_2_links'>
                  Відгуки
                </a> */}
              </div>
              <div className='contact'>
                <p className='footer_container_2_text' id='contact'>
                  Контакти
                </p>
                <a href='mailto:viakostee@gmail.com' className='footer_container_2_links'>
                  viakostee@gmail.com
                </a>
                <p className='footer_container_2_links'>+380 405 95 40</p>
                {/* <a href='#.com' className='footer_container_2_links'>
                  FAQ
                </a> */}
              </div>
            </article>
          </div>
          <hr className='hr' />
          {/* <p className='copyright'><span className='colored_copyright'>Copyright &copy; 2023 BRIX Templates | All Rights Reserved |</span><span className='colored_copyright2'><u>Terms and Conditions</u></span><span className='colored_copyright3'>|</span><span className='colored_copyright2'><u>Privacy Policy</u></span></p> */}
          <p className='createdBy'>Created by <u><a target='_blanc' href="https://www.linkedin.com/in/viacheslav-v-37553814b/">Viacheslav</a></u></p>
        </div>
      </footer>
    );
  }