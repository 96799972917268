import React, {useState, useEffect} from "react";
import {Navigate} from 'react-router-dom';
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import "../admin_panel.css"
import AdminPanelAside from "../../../components/AdminPanelAside/AdminPanelAside";
import './statistic.css'
import LoadingPage from "../../Loading/LoadingPage";

export default function Statistic(){

    const [userRole, setUserRole] = useState(null); // Initialize state for user role

    const [isLoading, setIsLoading] = useState(true);

    const [usersNumber, setUsersNumber] = useState('0');

    const [adminNumber, setAdminNumber] = useState('0');

    const [productNumber, setProductNumber] = useState('0');

    const [categoryNumber, setCategoryNumber] = useState('0');

    useEffect(() => {
        fetch('/api/getStatsNumbers')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setUsersNumber(data.num_users);
                setAdminNumber(data.num_admins);
                setProductNumber(data.num_products);
                setCategoryNumber(data.num_categories);
            })
            .catch(error => console.error('Error of getting user number', error))
    }, []);

    useEffect(() => {
        // Выполнение запроса к серверу для получения информации о пользователе
        fetch('/api/getUserInfo')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch user info');
                }
                return response.json();
            })
            .then(data => {
                setUserRole(data.role);
            })
            .catch(error => console.error('Ошибка при получении информации о пользователе:', error))
            .finally(() => setIsLoading(false));
    }, []);

    if (isLoading) {
        return <LoadingPage/>;
    }

    return (
        <>
            {userRole === "admin" ? (
                <>
                    <Header/>
                    <main>
                        <div className="container">
                            <div className="admin_panel">
                                <AdminPanelAside/>
                                <div className="admin_panel_window">
                                    <div className="stat_numbers">
                                        <p>Зареєстровано користувачів: {usersNumber}</p>
                                        <p>Кількість адміністраторів: {adminNumber}</p>
                                        <p>Кількість товарів: {productNumber}</p>
                                        <p>Кількість категорій: {categoryNumber}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <Footer/>
                </>
            ) : (
                <Navigate replace to="/" />
            )}
        </>
    );
}