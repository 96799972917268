import React, { useEffect, useState } from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import './product_page.css'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import wm from '../../img/wallpaper market.png'
import deactivatedImg from '../../img/deactivated-product.jpg'
import AdminProductEdit from '../../components/AdminProductEdit/AdminProductEdit'

export default function PoductPage() {
  const {id} = useParams(); // Получаем параметр id из URL
  const [product, setProduct] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [isAvail, setIsAvail] = useState(false);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    if (id) {
      axios.get(`/api/products/${id}/${id}`)
        .then(response => {
          setProduct(response.data);
          setIsAvail(response.data.is_avail === 1);
          setIsActive(response.data.is_active === 1);
        })
        .catch(error => {
          console.log('There was an error fetching the product!', error);
        });
    }
  }, [id]); // useEffect зависит от id

  useEffect(() => {
    fetch('/api/getUserInfo')
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to fetch user info');
            }
            return response.json();
        })
        .then(data => {
            setUserRole(data.role);
        })
        .catch(error => console.error('Error fetching user info:', error));
}, []);

  // Base URL for the server
  const baseURL = "http://localhost:8000/";

  if (!product) {
    return <div>Такого товару не існує</div>;
  }
  return (
    <>
        <Header/>
          <main className='main'>
            <div className='container3'>
              <div className="productWrapper">
                <div className="productGeneralInfo">
                  <img
                     className='product_image' 
                     src={`${baseURL}${product.image_path}`} 
                     alt="product_image" />
                  {/* {isActive ? (
                    <img
                     className='product_image' 
                     src={`${baseURL}${product.image_path}`} 
                     alt="product_image" />
                  ) : (
                    <div className='deactivatedImg'>
                      <img
                        className="product_image"
                        src={`${baseURL}${product.image_path}`}
                        alt="product_image"
                      />
                      <img
                        className="deactivated_overlay"
                        src={deactivatedImg}
                        alt="deactivated overlay"
                      />
                    </div>
                  )} */}
                  <div className="priceAndName">
                    <h1 className='productName'>{product.name}</h1>
                    <p className='availabilityOfProduct'>{isActive ? ( `В наявності ${isAvail ? "✔️" : "❌"}` ) : (<div></div>)}</p>
                    <p className='productPrice'>{isActive ? "Ціна:" + product.price + "₴" + "/" + product.unit : "Товар наразі недоступний"}</p>
                  </div>
                </div>
                <div className="productOwnerInfo">
                  <p>Продавець:</p>
                  <img className='productOwnerImg' src={wm} alt="Шпалерний ринок" />
                  <p className='productOwnerNumber'>Телефон: +380 405 95 40</p>
                </div>
              </div>
              <hr className='hr'/>
              <div className="productDescription">
                <p>Опис товару:<br/>{product.description}</p>
              </div>
            </div>
          </main>
          {userRole === "admin" ? (
              <AdminProductEdit/>
            ) : (
              <div></div>
            )
          }
        <Footer/>
    </>
  )
}