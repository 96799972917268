import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

export default function Error() {
  return (
    <>
    <Header/>
      <main className="main">
        <h1 className='error'>Error 404</h1>
        <p>Сторінка не знайдена</p>
      </main>
    <Footer/>
    </>
  );
}
