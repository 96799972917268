import React, {useState, useEffect} from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import category_logo from '../../img/category.png'
import './products.css'
import Product from '../../components/Product/Product'
// import Button from '../../components/Button/Button'
import axios from 'axios'
import { useParams, Link } from 'react-router-dom'

export default function Products() {

  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const {id} = useParams(); // Получаем параметр id из URL
  const [category, setCategory] = useState(null);

  useEffect(() => {
    if (id) { // Проверка наличия id
      axios.get(`/api/products/${id}`)
        .then(response => {
          setCategory(response.data);
        })
        .catch(error => {
          console.log('There was an error fetching the product!', error);
        });
    }
  }, [id]); // useEffect зависит от id

  useEffect(() => {
      fetch('/api/categories') // Отправка GET запроса к эндпоинту /categories на вашем сервере
        .then(response => response.json())
        .then(data => {
          setCategories(data);
        })
        .catch(error => console.error('Ошибка при получении данных о категориях:', error));
  }, []);

  useEffect(() => {
    fetch('/api/getProductInfo') // Отправка GET запроса к эндпоинту /products на вашем сервере
      .then(response => response.json())
      .then(data => {
        setProducts(data);
      })
      .catch(error => console.error('Ошибка при получении данных о товарах:', error));
  }, []);

  const getCategoryName = (categoryId) => {
    const category = categories.find(cat => cat.id === categoryId);
    return category ? category.name : 'Без категорії';
  };

  if (!category) {
    return <div>Такої категорії не існує</div>;
  }

  const filteredProducts = products.filter(product => product.category_id === parseInt(id));

  return (
    <>
      <Header/>
        <div className='container'>
          <aside className='categoryAside'>
            <h2 className='category_h2'><img className='category_logo' src={category_logo} alt="category_logo" />Категорії</h2>
            <ul className='category_ul'>
                {categories.length > 0 ? (
                  categories.map(category => (
                    <Link to={`/products/${category.id}`} key={category.id}>
                      <li className='category_li'>{category.name}</li>
                    </Link>
                  ))
                  ) : (
                    <li>На данний момент категорії відсутні</li>
                )}
            </ul>
          </aside>
          <div className='products_container'>
            <h1 className='products_container_h1'>{category.name}</h1>
            <div className='products_block'>
              {filteredProducts.length > 0 ? (
                filteredProducts.map(product => (
                  <Product
                    key={product.product_id}
                    product={product}
                    categoryName={getCategoryName(product.category_id)}
                  />
                ))
              ) : (
                <p>На данний момент товари відсутні</p>
              )}
            </div>
            {/* <div className='products_control'>
              <Button value={"<"}/>
              <Button value={">"}/>
            </div> */}
          </div>
        </div>
      <Footer/>
    </>
  )
}
