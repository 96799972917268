import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
// import ukrainian_language from '../../img/ukrainian_language.png';
// import search_button from '../../img/search_button.png';
// import add_shopping_cart from '../../img/add_shopping_cart.png';
import wallpaper_logo from '../../img/wallpaper_logo.png'
import './header.css';
import Modal from './Modal/Modal';

export default function Header() {

    // const [categories, setCategories] = useState([]);
    const [userEmail, setUserEmail] = useState(null);
    const [userRole, setUserRole] = useState(null);

    useEffect(() => {
        fetch('/api/getUserInfo')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch user info');
                }
                return response.json();
            })
            .then(data => {
                setUserEmail(data.email);
                setUserRole(data.role);
            })
            .catch(error => console.error('Error fetching user info:', error));
    }, []);

    // useEffect(() => {
    //     fetch('/categories') // Отправка GET запроса к эндпоинту /categories на вашем сервере
    //       .then(response => response.json())
    //       .then(data => {
    //         setCategories(data);
    //       })
    //       .catch(error => console.error('Ошибка при получении данных о категориях:', error));
    // }, []);

    return (
        <div className='header_wrapper'>
            <header className='header'>
                <div className='container'>
                    <Link to="/">
                        <img className='wallpaper_logo' src={wallpaper_logo} alt="wallpaper_logo" />
                    </Link>
                    {/* <form className='search'>
                        <input type='search' placeholder='Пошук' className='search_tab' />
                        <select name='category' id='category' className='category_select' defaultValue="all category">
                            <option value="all category" disabled>Всі категорії</option>
                            {categories.length > 0 ? (
                                categories.map(category => (
                                    <option key={category.id}>{category.name}</option>
                                ))
                            ) : (
                                <option disabled>На данний момент категорії відсутні</option>
                            )}
                        </select>
                        <button type='submit' className='search_submit_button'>
                            <img src={search_button} alt='Пошук' />
                        </button>
                    </form> */}
                    <nav className='nav'>
                        <div className='navigation'>
                            <ul className='navigation_menu'>
                                <li>
                                    <Link className='nav_link' to="/">Головна</Link>
                                </li>
                                <li>
                                    <Modal/>
                                </li>
                                <li>
                                    <a className='nav_link' href="#contact">Контакти</a>
                                </li>
                                {userEmail ? (
                                    userRole === "admin" ? (
                                        <li>
                                            <Link className='nav_link' to="/admin-panel">Панель адміністратора</Link>
                                        </li>
                                    ) : (
                                        <li>
                                            <Link className='nav_link' to="/account">{userEmail}</Link>
                                        </li>
                                    )
                                ) : (
                                    <li>
                                        <Link className='nav_link' to="/registration">Вхід/Реєстрація</Link>
                                    </li>
                                )}
                            </ul>
                            {/* <button onClick={openCartModal} className='shoping_cart'>
                                <img src={add_shopping_cart} alt='Корзина' className='add_shopping_cart_img' />
                                <p className='cart_text'>Кошик</p>
                            </button>
                            <div className={`modal ${isCartModalOpen ? 'show' : ''}`} onClick={closeCartModal} id='myModal2' >
                                <div className='modal-content' onClick={(e) => e.stopPropagation()}>
                                    <span className='close' onClick={closeCartModal}>&times;</span>
                                    <p className='empty_cart_text'>Ваш кошик пустий</p>
                                </div>
                            </div> */}
                            {/* <select name='language_bar' id='language_bar' className='language_bar'>
                                <option value="ukrainian">Українська</option>
                                <option value="english">English</option>
                                <option value="russian">Русский</option>
                                <option value="polish">Polska</option>
                            </select>
                            <img src={ukrainian_language} className='language_bar_flag' alt='ukrainian' /> */}
                        </div>
                    </nav>
                </div>
            </header>
        </div>
    );
}